import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../utils/formatDate';

interface IRenterAddonReturnListItem {
  reservationRow: any;
}

const RenterAddonReturnListItem = ({ reservationRow: listItem }: IRenterAddonReturnListItem) => {
  return (
    <TableRow key={listItem?.id}>
      <TableCell scope='row'>{listItem?.addons_name || 'N/A'}</TableCell>
      <TableCell scope='row' className='equip-assign-date'>
        {formatDate({ date: listItem?.startDate }) || 'N/A'}
      </TableCell>
      <TableCell scope='row' className='equip-assign-date'>
        {formatDate({ date: listItem?.endDate }) || 'N/A'}
      </TableCell>
      <TableCell>
        <Box
          component='span'
          className={`badge text-capitalize ${
            listItem?.isReturned ? 'badge--green' : 'badge--red'
          } text-capitalize`}
        >
          {listItem?.isReturned ? 'Yes' : 'No'}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default RenterAddonReturnListItem;

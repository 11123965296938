import moment from 'moment-timezone';

interface IFormatDate {
  date: string | number;
  requiredHour?: boolean;
}
export const formatDate = ({ date, requiredHour = false }: IFormatDate) => {
  const momenDate = moment(date).isValid() ? moment.utc(date) : null;
  if (!momenDate) return 'N/A';
  return momenDate.format(`MM/DD/YYYY ${requiredHour ? 'HH:mm' : ''}`);
};

export const formatDateTimeAccTimezone = (dateTime: Date | string) => {
  const userTimeZone = moment.tz.guess() || 'UTC';
  return moment(dateTime).isValid()
    ? moment(dateTime).tz(userTimeZone).format('MM/DD/YYYY hh:mm a')
    : '';
};
